import './App.css';
import { Link, BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from "./images/logo.png"
import hero from "./images/hero.png"
import subhero1 from "./images/subhero1.png"
import subhero2 from "./images/subhero2.png"
import subhero3 from "./images/subhero3.png"
import finalhero from "./images/finalhero.png"

import amazon from "./images/amazon.png"
import hepsiburada from "./images/hepsiburada.png"
import n11 from "./images/n11.png"
import eptt from "./images/epttavm.png"
import trendyol from "./images/trendyol.png"

import twitter from "./images/twitter.png"
import youtube from "./images/youtube.png"
import instagram from "./images/instagram.png"
import facebook from "./images/facebook.png"

import spectreProfile from "./images/spectreProfile.png"
import spectre1 from "./images/spectre1.png"
import spectreSubhero from "./images/spectreSubhero.png"
import spectreSubhero2 from "./images/spectreSubhero2.png"
import trueRGB from "./images/trueRGB.png"

import aboutbg from "./images/aboutbg.png"


import { useState } from 'react';

function App() {
  return (
    <div id='App'>    
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/products' element={<Products/>}></Route>
        <Route path='/spectre' element={<Spectre/>}></Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
    </div>
  );
}

function Header(){
  return(
    <div id='Header'>
      <Link to="/"><img src={logo} alt='logo'/></Link>
      <div id='Menu'>
        <Link to="/">Anasayfa</Link>
        <Link to="/products">Ürünler</Link>
        <Link to="/about">Hakkımızda</Link>
      </div>
    </div>
    
  )
}

function Home(){
  return(
    <div id='Home'>
      <div id="Hero">
        <div id="HeroText">
          <h1>Oyunda Maksimum Performans</h1>
          <span>Muhteşem True RGB ışıklandırma ve Hotswap Mekanik Switch teknolojilerine sahip <span style={{fontWeight: "bold"}}>Spectre Oyuncu Klavyesi</span> ile tanışın.</span>
          <Link to="/spectre"><button>Spectre'yi İncele</button></Link>
        </div>
        <img src={hero} alt="hero" />
      </div>
      <div id='SubHero'>
        <div id='SubHeroCell'>
          <img src={subhero2} alt="subheroimg"/>
          <h2>True RGB ile Tarzını Yansıt</h2>
          <span>Tüm tuşları 16.8 milyon renk True RGB aydınlatma teknolojisi ile gelen <span style={{fontWeight: "bold"}}>Spectre Oyuncu Klavyesi</span> ile odana ve oyununa stil kat.</span>
        </div>
        <div id='SubHeroCell'>
          <img src={subhero1} alt="subheroimg"/>
          <h2>Sıfır Takılma, Maksimum Performans</h2>
          <span>Yepyeni <span style={{fontWeight: "bold"}}>Spectre Oyuncu Klavyesi</span>, tuş vuruşlarınızı anında algılar ve kaliteli Mekanik Switchleri ile sizi yormadan performansınızı en yüksek seviyede tutar.</span>
        </div>
        <div id='SubHeroCell'>
          <img src={subhero3} alt="subheroimg"/>
          <h2>Kalitenin Zirvesi ile Oyuna Devam</h2>
          <span>50+ milyon tuş vuruşu ömrüne sahip olan Mekanik Switchler sizi yarı yolda bırakmaz. <span style={{fontWeight: "bold"}}>Spectre Oyuncu Klavyesi</span>, 400 gram ağırlığı ile her zaman yanınızda.</span>
        </div>
      </div>
      <div id='BuySection'>
        <h1>Ürünlerimizi Alın</h1>
        <span>BSG Culture E-Sports ürünlerini aşağıdaki E-Ticaret sitelerinden satın alabilirsiniz.</span>
        <div id='eCommerce'>
          <a href="https://www.hepsiburada.com/bsgcultureesports"><img src={hepsiburada} alt="" /></a>
          <a href="https://www.n11.com/magaza/bsg-culture-e-sports"><img src={n11} alt="" /></a>
          <a href="https://www.amazon.com.tr/s?me=A3TW3ZC4Z6HLCD&marketplaceID=A33AVAJ2PDY3EV"><img src={amazon} alt="" /></a>
          <a href="https://www.pttavm.com/magaza/bsgcultureesports"><img src={eptt} alt="" /></a>         
          <a href="https://www.trendyol.com/magaza/bsg-culture-e-sports-m-893475?sst=0"><img src={trendyol} alt="" /></a>
        </div>
      </div>
      <div id='FinalHero'>
        <div id='FinalHeroText'>
          <h1>BSG Culture E-Sports ile Oyuna Devam</h1>
          <span>Hepimiz oyunun içindeyiz, ama her birimiz kendi özel tercihlerimize ve motivasyonlarımıza göre. Bu topluluğun muhteşemliği, hep birlikte şekillendirdiğimiz içindir. Oyunda birçok seviye bulunsa da, aramızda seviye farkı yok. Nereye ilerleyeceğimize sadece biz karar veririz.</span>
          <Link to="/products"><button style={{justifySelf: "center"}}>Ürünlere Gözat</button></Link>
        </div>
        
        <img src={finalhero} alt="finalhero"/>
      </div>
    </div>
  )
}

function Products(){
  return(
    <div id='Products'>
      <h1>BSG Culture E-Sports</h1>
      <h2>Oyuncu Ekipmanları</h2>
      <div id='ProductsSection'>
        <div id='ProductCell'>
          <img src={spectreProfile} alt="spectreProfil" />
          <h2>Spectre Mekanik Klavye</h2>
          <Link to="/spectre"><button>Ürünü İncele</button></Link>
        </div>
      </div>
    </div>
  )
}

function Spectre(){
  const [expandButton, setExpandButton] = useState("+")
  const [compExpandButton, setCompExpandButton] = useState("+")
  const [boxExpandButton, setBoxExpandButton] = useState("+")

  async function handleExpand(){
    if(expandButton === "+"){
      document.getElementById("ProductSpecsExpand").style.setProperty("height", "100%")
      setExpandButton("-")
    }else{
      document.getElementById("ProductSpecsExpand").style.setProperty("height", "0")
      setExpandButton("+")
    }
  }

  async function handleCompExpand(){
    if(compExpandButton === "+"){
      document.getElementById("ProductCompExpand").style.setProperty("height", "100%")
      setCompExpandButton("-")
    }else{
      document.getElementById("ProductCompExpand").style.setProperty("height", "0")
      setCompExpandButton("+")
    }
  }

  async function handleBoxExpand(){
    if(boxExpandButton === "+"){
      document.getElementById("ProductBoxExpand").style.setProperty("height", "100%")
      setBoxExpandButton("-")
    }else{
      document.getElementById("ProductBoxExpand").style.setProperty("height", "0")
      setBoxExpandButton("+")
    }
  }

  return(
    <div id='Spectre'>
      <div id='ProductHero'>
        <div id='ProductHeroLeft'>
          <img src={spectre1} alt="spectre1" />
        </div>
        <div id='ProductHeroRight'>
          <h1>Spectre</h1>
          <h3>Mekanik Oyuncu Klavyesi</h3>
          <br /><br />
          <span>BSG Culture E-Sports markasının mihenk taşı olan Spectre Mekanik Oyuncu Klavyesi, tüm oyuncular için özel olarak tasarlanmıştır. Maksimum konfor, performans ve kaliteye erişmek isteyenlerin bir numaralı tercihidir. Oyun sınıfı mekanik switchlere, True RGB aydınlatma teknolojisine ve taşınabilirlik için 400 gram ağırlığa sahiptir.</span>
          <br /><br />
          <div id='ProductSpecs'>
            <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
              <h2>Özellikler ve Ayrıntılar</h2>
              <button onClick={handleExpand}>{expandButton}</button>
            </div>
            <div id='ProductSpecsExpand'>
              <h3>Fiziksel Özellikler</h3>
              <span>Uzunluk: 31 cm</span>
              <span>Genişlik: 10.5 cm</span>
              <span>Yükseklik: 3.6 cm</span>
              <span>Ağırlık: 400 gram</span>
              <br />
              <h3>True RGB Aydınlatma</h3>
              <span>Her tuşta 16.8 milyon renk</span>
              <br />
              <h3>68 adet Hotswap mekanik switch</h3>
              <br />
              <h3>Garanti Bilgileri</h3>
              <span>2 Yıl Sınırlı Donanım Garantisi</span>
            </div>
          </div>
          <div id='ProductSpecs'>
            <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
              <h2>Uyumluluk</h2>
              <button onClick={handleCompExpand}>{compExpandButton}</button>
            </div>
            <div id='ProductCompExpand'>
              <span>USB Bağlantı Noktası</span>
              <span>Windows, Mac ve Linux uyumlu</span>
              <span>Yazılım kurulumu gerektirmez</span>
              <span>Windows XP ve üzeri</span>
              <span>Klavyeye bağlanıp sökülebilen Type-C to USB kablosu gereklidir</span>
            </div>
          </div>
          <div id='ProductSpecs'>
            <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
              <h2>Kutu İçeriği</h2>
              <button onClick={handleBoxExpand}>{boxExpandButton}</button>
            </div>
            <div id='ProductBoxExpand'>
              <span>Spectre Mekanik Oyuncu Klavyesi</span>
              <span>Örgülü Type-C to USB Kablo</span>
              <span>Kullanım Kılavuzu</span>
            </div>
          </div>
        </div>
      </div>
      <div id='ProductSubhero'>
        <h1>Oyunda Maksimum Performans</h1>
        <span style={{width:"50%", justifySelf:"center"}}><span style={{fontWeight:"bold"}}>Spectre</span> Mekanik Oyuncu Klavyesi, üstün performansı ve şık tasarımıyla oyun tutkunları için vazgeçilmez bir seçenek. En heyecanlı oyun deneyimlerini yaşamak için şimdi satın alın ve zaferin tadını çıkarın!</span>
        <div id='ProductSubheroTrio'>
          <div id='ProductSubheroCell'>
            <h2>Konfor ve Performans İçin Tasarlandı</h2>
            <span>Spectre, yapısı itibarı ile konfor ve performansı bir araya getirerek oyun deneyiminizi mükemmelleştiriyor.</span>
          </div>
          <div id='ProductSubheroCell'>
            <h2>True RGB Aydınlatma</h2>
            <span>Spectre'de bulunan 16.8 milyon renkli RGB tuşlar, görsel olarak etkileyici ve kişiselleştirilebilir bir deneyim sunuyor.</span>
          </div>
          <div id='ProductSubheroCell'>
            <h2>Dayanıklı ve Hotswappable</h2>
            <span>Spectre, 50 milyondan fazla tuş vuruş ömrü ve switch değiştirme teknolojisi ile uzun kullanım ömrü ve özelleştirme imkanı sunuyor.</span>
          </div>
        </div>
      </div>
      <div id='ProductSubhero2'>
        <div id='SpectreSubheroText'>
          <h1>Her Yerde Oynamak İçin Tasarlandı</h1>
          <div id='SpectreSubheroBorder'></div>
          <span>Gidilecek maçlar, buluşulacak takımlar, keşfedilecek yerler var ise, <span style={{fontWeight:"bold"}}>Spectre</span> her zaman yanında. Rekabetçi oyunlar için tasarlanmış olan <span style={{fontWeight:"bold"}}>Spectre</span>, zafere giden yolda size eşlik eder ve galibiyeti size getirir. Bağlantı kolaylığı sağlamak için çıkartıp-takılabilen Type-C to USB kablosu ve sürücü kurulumu gerektirmeyen yapısıyla, <span style={{fontWeight:"bold"}}>Spectre</span> ile oyuna her zaman, her yerde hazır olun!</span>
        </div>
        <img src={spectreSubhero} alt="spectreSubhero" />
      </div>
      <div id='ProductSubhero3'>
        <img src={spectreSubhero2} alt="spectreSubhero2" />
        <div id='ProductSubhero3Subtext'>
          <img src={trueRGB} alt="trueRGB" style={{width:"64px"}}/>
          <h1>True RGB Aydınlatma Her An Yanında</h1>
          <div id='SpectreSubheroBorder'></div>
          <span>Birlikte senkronize olarak çalışan, her biri 16.8 milyon renk RGB aydınlatmaya sahip olan mekanik switchler ile sıra dışı tarzını bulunduğun yere yansıt. <span style={{fontWeight:"bold"}}>Spectre</span>'nin birbirinden farklı ve etkileyici aydınlatma seçeneklerine sadece 2 tuş kombinasyonu ile ulaşabilirsiniz.</span>
        </div>
      </div>
    </div>
  )
}

function About(){
  return(
    <div id='About'>
      <div id='AboutHero'>
        <h1 style={{padding:"10%"}}>BSG Culture E-Sports ile Oyuna Devam</h1>
        <img src={aboutbg} alt="aboutbg" />
      </div>
      <div id='AboutSubhero2'>
        <h1>Rekabetçi Oyunlar İçin Rekabetçi Ekipmanlar</h1>
        <div id='SpectreSubheroBorder'></div>
        <span>Bizler, oyunların artık bir spor haline geldiğinin ve bunun için gerekli olan ekipmanların farkındayız. Bu ihtiyacı her açıdan karşılamaya ve tüm oyunculara değer katmaya hazırız. Ayrıca, oyuncu topluluğuna kaliteyi, performansı ve şıklığı erişilebilir fiyatlarla sunma konusuna kendimizi adamış durumdayız.</span>
      </div>
      <div id='AboutSubhero'>
        <h1 style={{color:"mediumaquamarine"}}>Performans ve Teknoloji Mainimiz</h1>
        <span>BSG Culture E-Sports, performansın sınırlarını zorlamayı ve oyunun potansiyelini keşfetmeyi hedefleyen bir takımdır. Oyun dünyası sürekli olarak değişmektedir ve biz de bu değişimlere ayak uydurma konusunda kararlıyız. Daha yüksek kaliteli ekipmanlar ve oyun entegrasyonu sayesinde sürekli olarak daha iyi bir oyun deneyimi sunuyoruz. True RGB aydınlatma teknolojisinden, oyuncu ergonomisine kadar çeşitli özelliklerimizle oyuncuları desteklemeye devam ediyoruz.</span>
      </div>
      <div id='AboutSubhero2'>
        <h1>Bizimle İletişime Geçin</h1>
        <div id='SpectreSubheroBorder'></div>
        <span>Aklınızda eğer herhangi bir soru var ise ve ya bize geri bildirimde bulunmak isterseniz aşağıdaki kanallardan bizlere ulaşabilirsiniz!</span>
        <button onClick={e => window.open("https://wa.me/+905518807393")} style={{background:"lightgreen"}}>WhatsApp'tan Bize Yazın</button>
        <button onClick={e => window.open("mailto:bsgculture@gmail.com")} style={{background:"lightblue"}}>Bize E-Mail Gönderin</button>
        <span>E-Mail: bsgculture@gmail.com</span>
      </div>
      
    </div>
  )
}

function Footer(){
  return(
    <div id='Footer'>
      <div id='FooterLeft'>
        <Link to="/">Anasayfa</Link>
        <Link to="/products">Ürünler</Link>
        <Link to="/about">Hakkımızda</Link>
      </div>
      <div id='FooterMid'>
        <Link to="/"><img src={logo} alt='logo'/></Link>
        <span>BSG Culture E-Sports - 2023</span>
      </div>
      <div id='FooterRight'>        
        {/*<a href="https://www.facebook.com/bsgculture"><img src={facebook} alt="facebook"/></a>*/}
        <a href="https://twitter.com/bsgculture"><img src={twitter} alt="twitter"/></a>
        <a href="https://www.instagram.com/bsgculture/"><img src={instagram} alt="instagram"/></a>
        <a href="https://www.youtube.com/@bsgculture"><img src={youtube} alt="youtube"/></a>
      </div>
    </div>
  )
}

export default App;
